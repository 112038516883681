<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                Edit Ticket Issue
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.fullName"
                                          :label="$t('full_name')"
                                          dense
                            outlined
                                          required></v-text-field>
                        </v-col>
                        <!--<v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.firstName"
                                          label="First Name "
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.lastName"
                                          label="Last Name"
                                          required></v-text-field>
                        </v-col>-->
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-radio-group v-model="customerData.gender" row :label="$t('gender')">
                                <v-radio :label="$t('male')" value="Male"></v-radio>
                                <v-radio :label="$t('female')" value="Female"></v-radio>
                                <v-radio :label="$t('others')" value="Other"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.dateOfBirth"
                                                  :label="$t('dob')"
                                                  clearable
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  dense
                                                  outlined
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.dateOfBirth">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.nationality"
                                          :label="$t('nationality')"
                                          dense
                                                  outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.country"
                                            :items="countries"
                                            :label="$t('country')"
                                            clearable
                                            single-line
                                            dense
                                                  outlined
                                            item-text="value"
                                            item-value="id"
                                            @change="getState"></v-autocomplete>
                        </v-col>

                        <!--<v-col cols="12"
           sm="6"
           md="4">
        <v-autocomplete v-model="customerData.state"
                        :items="state"
                        label="State"
                        clearable
                        single-line
                        item-text="value"
                        item-value="id"></v-autocomplete>
    </v-col>-->


                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.ticketNumber"
                                          :label="$t('ticket_number')"
                                          dense
                                                  outlined
                                          required></v-text-field>
                        </v-col>
                        <!--<v-col cols="12"
           sm="6" md="4">
        <v-autocomplete v-model="customerData.customerType" :items="PassengerTypes"
                        item-value="value"
                        item-text="value"
                        label="Age Group"></v-autocomplete>
    </v-col>-->
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.serviceID"
                                            :items="Services"
                                            :label="$t('ticket_category')"
                                            item-text="value"
                                            dense
                                                  outlined
                                            item-value="id"
                                            @change="changePaidAmount"></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field type="number"
                                          v-model="customerData.paidAmount"
                                          :label="$t('paid_amount')"
                                          dense
                                                  outlined
                                          required
                                          readonly></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete :items="payType"
                                            v-model="customerData.paymentType"
                                            :label="$t('payment_type')"
                                            dense
                                                  outlined
                                            required></v-autocomplete>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.citizenShipNumber"
                                          :label="$t('citizenshipNumber')"
                                          dense
                                                  outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.passportNumber"
                                          :label="$t('passport_number')"
                                          dense
                                                  outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.licenseNumber"
                                          dense
                                                  outlined
                                          :label="$t('licenseNumber')"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.otherID"
                                          dense
                                                  outlined
                                          :label="$t('otherID')"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.document"
                                          dense
                                                  outlined
                                          :label="$t('documents')"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-switch class="col-sm-6 col-md-4"
                                      v-model="customerData.isVerifiedDocument"
                                      dense
                                                  outlined
                                      :label="$t('is_verified_document')"
                                      clearable></v-switch>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.issuedBy"
                                          :label="$t('issued_by')"
                                          dense
                                                  outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.issuedDate"
                                                  :label="$t('issued_date')"
                                                  dense
                                                  outlined
                                                  clearable
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.issuedDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                </v-container>
            </v-form>

        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="editFormSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "HelicopterCustomerEditForm",
        data() {
            return {
                AirPackages: [],
                customerData: [],
                customerFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu: false,
                    dateMenu2: false,
                    dateMenu3: false,
                },
                countries: [],
                state: [],
                airList: [],
                Destinations: [],
                Services: [],
                PassengerType: [],
                FlightClasses: ['a'],
                Issuer: ['E-Sewa'],
                PassengerTypes: [],
                payType: ["Cash", "Visa Card"],
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            }
        },
        watch: {
            "customerData.country": {
                handler: async function (val) {
                    if (val) {
                        const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                        this.state = stateList.data
                    }
                },
                deep: true
            }
        },
        props: ['editItemIndex']
        ,
        computed: {},
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },

            editFormSubmit() {
                this.$delete(this.customerData, 'createdBy')
                this.customerData.IsImportFromExcel = false
                this.customerData.issuedDate = this.customerData.issuedDate == "" ? null : this.customerData.issuedDate
                axios.post('Pilgrimage/UpdateCustomerInformationAsync', this.customerData)
                    .then((response) => {
                        if (response.data.success) {
                            this.message.status = true
                            this.servicesData.data = response.data
                            this.servicesData.message = "Issue updated Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    })
                    .catch((response) => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()
                    })
            },
            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            formData() {
                axios.get("Shared/GetAgeGroupList").then(response => {
                    this.PassengerTypes = response.data;
                }).catch(error => console.log(error.response))
                axios.get("Shared/CountryList").then(response => {
                    this.countries = response.data;
                }).catch(error => console.log(error.response))
                axios.get("Pilgrimage/DDLPilgrimageServiceListAsync").then(response => {
                    this.Services = response.data;
                }).catch(error => console.log(error.response))
                this.getEditData().then(response => {
                    this.customerData = response.data
                    console.log(response.data)
                }).catch(err => console.log(err.response))
            },
            async getEditData() {
                return await axios.get('Pilgrimage/GetCustomerInformationByIDAsync/' + this.editItemIndex)
            },
            changePaidAmount(data) {
                let paidService = this.Services.filter(service => service.id === data)
                this.customerData.paidAmount = paidService[0].amount
            }
        },
        mounted() {
            this.formData();
        },
    }
</script>

<style scoped>

</style>